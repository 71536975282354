import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { docuemntReady, handleSelect, initPopupMenu, initSelect } from '../../../common/javascriptHelper';
import { validateEamil } from '../../../common/stringHelper';
import { useAuth } from '../../../context/AuthContext';
import { useApi } from '../../../context/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const NewProjectPopup = props => {

  const {popupData, onCancel, onConfirm} = props

  const auth = useAuth()
  const api = useApi()
  const {confirm, alert} = useCommonPopup()

  const [step, setStep] = useState(1)
  
  const [projectData, setProjectData] = useState({
    name: "",
    eCRF: 0,
    collaborators: [],
    invitees: []
  })

  
  const [nameData, setNameData] = useState({
    name: projectData.name,
    error: "",
  })
  const [emailData, setEmailData] = useState({
    email: "",
    error: "",
  })
  const [eCRF, setECRF] = useState(false)
  
  const nameInputRef = useRef(null)
  const emailInputRef = useRef()
  const newProjectId = useRef(null)

  const [emailDatas, setEmailDatas] = useState(projectData.collaborators)
  const [invitees, setInvitees] = useState(projectData.invitees)

  useEffect(()=> {
    
    return () => {
    }
  }, []);

  useEffect(()=> {
    
    initPopupMenu()      
    $(`.new_project_pop, .popup_inwrap, .step${step}`).fadeIn();
    if (step == 1) {
      setTimeout(() => { 
        nameInputRef.current?.focus()
      }, 100)
    } else if (step == 2) {
      setTimeout(() => { 
        emailInputRef.current?.focus()
      }, 100)
    }

  }, [step]);

  useEffect(()=> {
    
    initPopupMenu()

  }, [emailDatas, invitees]);


  const onChangeName = (e) => {

    var {value} = e.target
    var name = value
          
    var error = ""    
    if (name.length > 30) error = "이름은 최대 30자를 넘을 수 없습니다."

    setNameData({
      ...nameData,
      name: name,
      error: error
    })
  }
  const onKeyEventName = (e) => {

    if (e.key === 'Enter') {
      onClickStep2()
    }
  }

  const onClickStep2 = () => {

    if (nameData.name.length == 0) {
      setNameData({
        ...nameData,
        error: "프로젝트 이름을 입력해 주세요."
      })
      return        
    } else if (nameData.name.length > 30) {
      setNameData({
        ...nameData,
        error: "이름은 최대 30자를 넘을 수 없습니다."
      })        
      return
    }
    setNameData({
      ...nameData,
      error: ""
    })
    setProjectData({
      ...projectData,
      ["name"]: nameData.name,
      ["eCRF"]: eCRF ? 1 : 0
    })
    setStep(2)
  }


  const onChangeEmail = (e) => {

    var {value} = e.target
    var email = value.replace(/ /g, "") 
          
    var error = ""    

    setEmailData({
      ...emailData,
      email: email,
      error: error
    })
  }

  const onKeyEventEmail = (e) => {

    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const onClickSelect = (e) => {

    handleSelect(e)
  }

  const checkEmail = () => {
    if (emailData.email.length == 0) {
      setEmailData({
        ...emailData,
        error: "이메일을 입력해 주세요."
      })
      return false       
    } 
    if (!validateEamil(emailData.email)) {
      setEmailData({
        ...emailData,
        error: "이메일 형식에 맞지 않습니다. 다시 확인해 주세요."
      })        
      return false
    } 
    if (auth.adminInfo.email == emailData.email) {
      setEmailData({
        ...emailData,
        error: "이 이메일은 이미 등록된 작업자입니다."
      })        
      return false
    } 
    const exist = emailDatas.filter(item => item.email == emailData.email)       
    if (exist.length > 0) {
      setEmailData({
        ...emailData,
        error: "이 이메일은 이미 등록된 작업자입니다."
      })  
      return false
    }   
    return true
  }

  const onClickAdd = async () => {

    if (!checkEmail()) {
      alert("올바른 이메일을 입력해 주세요.")
      return
    }

    const result = await api.post("inviteCollaborator", {
      email: emailData.email,
    })
    
    if (result?.data?.result != "ok") {
      if (result.data.code == 240) {
        if (await confirm(`${emailData.email}님은 아직 Research Flow에 가입되어 있지 않습니다. 
공동작업자로 추가하기 위해 초대장을 발송할 예정입니다. 
계속 진행을 원하시면 확인 버튼을 눌러주세요.`,  {title: "초대장 발송"})) {
          addInvitees(emailData.email)
        } else {
          setEmailData({
            ...emailData,
            email: "",
            error: ""
          })  
        }
      } else if (!!result.data?.msg) {
        setEmailData({
          ...emailData,
          error: result.data?.msg
        })  
      } else {
        api.networkError(result, alert)
      }
    } else {
      addCollaborator(emailData.email)
    }
  }

  const addCollaborator = (email) => {        
    setEmailDatas([
      ...emailDatas, 
      email
    ]) 
    setEmailData({
      ...emailData,
      email: "",
      error: ""
    }) 
  }

  const addInvitees = (email) => {        
    setInvitees([
      ...invitees, 
      email
    ]) 
    setEmailData({
      ...emailData,
      email: "",
      error: ""
    }) 
  }
 
  const onClickAuthority = (e) => {

    handleSelect(e)
  }

  const onClickDelete = (email) => {
    setEmailDatas(emailDatas.filter(item => item !== email));
  }
  
  const onClickDeleteInvitees = (email) => {
    setInvitees(invitees.filter(item => item !== email));
  }

  const onClickStep1 = () => {
    setStep(1)
  }

  const onClickStep3 = async () => {
    
    var params = {
      ...projectData,
      ["collaborators"]: emailDatas.concat(invitees),
    }
    setProjectData(params)
        
    const result = await api.post("createProject", params)
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      newProjectId.current = result.data.data.project
      setStep(3)
    }
  }
 
  const onClickClose = () => {

    if (!!onCancel) {        
      onCancel(popupData, false)
    }
  }

  const onClickProject = () => {

    if (!!onConfirm) {
      onConfirm(popupData, newProjectId.current)
    }
  }

  const Step1 = () => {

    return (
      <div className="step1" >
        <div className="pop_content">
          <ul className="steps">
            <li className="on"><span>1</span></li>
            <li><span>2</span></li>
          </ul>
          <h4>프로젝트 이름을 입력해주세요.</h4>
          <div>
            <label htmlFor="project_name" >프로젝트 이름 (최대 30자)</label>
            <input 
              id="project_name" 
              type="text" 
              ref={nameInputRef}
              className={`full big ${nameData.error.length > 0 ? "error" : ""}`}
              placeholder="예) 당뇨환자의 수면장애" 
              value={nameData.name}
              onChange={onChangeName}
              onKeyUp={onKeyEventName}   
            />
            <p className="tip">{nameData.error}</p>
          </div>
          <p className="text_secondaty">프로젝트 이름은 추후 언제든지 수정 가능합니다. </p>
          { auth.adminInfo?.isecrf === 1 &&
            <p className='checkbox'>
              <label>
                <input type="checkbox" name="checkbox" checked={eCRF} onChange={(e) => {setECRF(e.target.checked)}}/><span>eCRF(전자증례기록)으로 진행하는 프로젝트인가요?</span>
              </label> 
              <span className='tooltip'>
                <i className='icon-infocircle '></i> 
                <span className="tooltiptext tooltip-top">eCTF(전자증례기록)이란? <br/>
                임상시험 계획서에 따라서 각각의 시험 대상자에 대하여 요구되어지는 정보를 기록하여 임상시험 의뢰자에게 보고 및 전달되어지는 정보의 점검 가능한 전자 기록을 말합니다.</span>
              </span>
            </p>
           }
        </div>
        <div className="pop_foot">
          <div className="but_wrap">
            {/* <div>
              <button type="button" className="disabled secondary gray large pop_close" onClick={onClickClose}>취소</button>
            </div> */}
            <div>
              <button type="button" 
                className={`primary large go_step2 ${nameData.name?.length === 0 ? "disabled" : ""}`} 
                disabled={nameData.name?.length === 0} 
                onClick={onClickStep2}
              >다음</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Step2 = () => {

    return (
      <div className="step2">
        <div className="pop_content">
          <ul className="steps">
            <li className="ok"><span></span></li>
            <li  className="on"><span>2</span></li>
          </ul>
          <h4>공유할 작업자가 있다면 이메일을 입력해주세요.  <br/>
            없으면 다음 단계로 진행해주세요.</h4>
          <div>
            <label htmlFor="join_mail" >이메일</label>
            <div className="vertical_form">
              <div>
                <div className="selectBox select_inline">
                  <span onClick={onClickSelect}>참여자</span>
                  {/* <ul className="optionList">
                      <li className="optionItem" onClick={() => onSelectOwner(0)}>참여자</li>
                      <li className="optionItem" onClick={() => onSelectOwner(1)}>소유자</li>
                  </ul> */}
                </div>
                <input 
                  id="project_mail" 
                  type="text" 
                  ref={emailInputRef}
                  className={`full big ${emailData.error.length > 0 ? "error" : ""}`}
                  placeholder="예) af@atflow.co.kr" 
                  value={emailData.email}
                  onChange={onChangeEmail}
                  onKeyUp={onKeyEventEmail}                   
                />                
              </div>
              <div >
                <button className="full" onClick={onClickAdd}>추가</button>
              </div>
            </div>
            <p className="tip">{emailData.error}</p>            
          </div>
          <div>
            <div className="vertical_form">
              <div>
              <i className="icon-mail"></i>{auth.adminInfo.email} (나)
              </div>
              <div>
                <div className="selectBox select_inline">
                  <span className="">소유자</span>
                </div>
              </div>
            </div>
            {
              emailDatas.map((item, index) =>
                <div className="vertical_form people" key={`collaborator-${index}`}>
                  <div>
                    <i className="icon-mail"></i> {item}
                  </div>
                  <div>  
                    <div className="selectBox select_inline">
                      <span className="label" onClick={(e)=>onClickAuthority(e)}>참여자</span>
                      <ul className="optionList">
                        <li className="optionItem" onClick={() => {onClickDelete(item)}}>삭제</li> 
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
            {
              invitees.map((item, index) =>
                <div className="vertical_form people" key={`invitees-${index}`}>
                  <div>
                  <i className="icon-mail"></i>{item}
                  </div>
                  <div>
                    <div className="selectBox select_inline">
                      <span className="label" onClick={(e)=>onClickAuthority(e)}>초대장 발송예정</span>
                      <ul className="optionList">
                        <li className="optionItem" onClick={() => {onClickDeleteInvitees(item)}}>삭제</li> 
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <br/>
          <p className="text_secondaty">프로젝트 공동작업자로 지정되면 프로젝트 내 모든 설문의 접근이 가능합니다. </p>    
        </div>
        <div className="pop_foot">
          <div className="but_wrap">
            <div>
              <button type="button" className="secondary gray large go_step1" onClick={onClickStep1}>이전</button>
            </div>
            <div>
              <button type="button" className="primary  large go_step3" onClick={onClickStep3}>다음</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Step3 = () => {

    
    return (
      <div className="step3">
        <div className="pop_content">
          <ul className="steps">
            <li className="ok"><span></span></li>
            <li  className="ok"><span></span></li>
            <li  className="on"><span>3</span></li>
          </ul>
          <h4>🎉  <br/>
            프로젝트가 추가되었습니다!</h4>
          <p className="text_secondaty">이제 본격적인 설문 컨텐츠를 채워보세요.  </p>
        </div>
        <div className="pop_foot">
          <div className="but_wrap">
            <div>
              <button type="button" className="primary  large pop_close" onClick={onClickProject}>프로젝트 들어가기</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">새 프로젝트  <span className="close_btn" onClick={onClickClose}></span></h5> 
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          {step == 1 && Step1()}
          {step == 2 && Step2()}
          {step == 3 && Step3()}
        </form>
      </div>         
      {/* <div className="dim_bg"></div> */}
    </div>    
  );  
}
 export default NewProjectPopup;