import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { PARTICIPANT_STATUS } from '../../../common/codeHelper';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const ChangeStatusPopup = props => {

  const api = useApi()
  const {alert} = useCommonPopup()  

  const {popupData, onCancel, onConfirm} = props  
  const { projectData, participantList } = props

  const [name, setName] = useState("")  
  const [error, setError] = useState("")  
  const [status, setStatus] = useState("")
  
  useEffect(_ => {
    
    setName(participantList.map((participant) => (participant.identifier))?.join(", "))
    setStatus(participantList?.[0]?.status?? "")

  }, [])


  useEffect(_ => {
    
    if (status === "WITHDRAW" ) {
      setError("탈퇴한 후에는 더 이상 상태를 변경할 수 없으며,\n참여자가 응답한 설문 결과가 모두 삭제됩니다.")
    } else if (status === "HALT" ) {
      setError("중단한 후에는 더 이상 설문을 진행할 수 없습니다.\n그러나 이미 진행한 설문에 대한 결과는 삭제되지 않고 유지됩니다.")
    } else {
      setError("")
    }

  }, [status])


  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onChangeStatus = (status) => {

    setStatus(status)
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {

    if (status?.length == 0) {
      setError("변경할 상태를 선택해 주세요.")
      return
    }

    const result = await api.post("updateParticipantStatus", {
      project: projectData.idproject,
      status: status,
      participants: participantList.map((participant) => (participant.idparticipant))
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg) {
        setError(result?.data?.msg)
      } else {
        api.networkError(result, alert)
      }
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }   
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">상태 변경 <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto  ">
              <h4>{name}님의 상태를 선택해주세요.
              </h4>
              <div className="selectBox large">
                <span className="label" onClick={onClickSelect}>{status?.length > 0 ? PARTICIPANT_STATUS[status] : "변경할 상태를 선택해 주세요."}</span>
                <ul className="optionList">
                  <li className="optionItem" onClick={() => onChangeStatus("PROCEED")}>{PARTICIPANT_STATUS["PROCEED"]}</li>
                  <li className="optionItem" onClick={() => onChangeStatus("WITHDRAW")}>{PARTICIPANT_STATUS["WITHDRAW"]}</li>
                  <li className="optionItem" onClick={() => onChangeStatus("HALT")}>{PARTICIPANT_STATUS["HALT"]}</li>
                </ul> 
              </div>
              <p className="tip" style={{whiteSpace:"pre-line"}}>{error}</p>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                </div>
                <div>
                  <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </div>
  );  
}
 export default ChangeStatusPopup;