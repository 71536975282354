import $ from 'jquery';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { initPopupMenu } from "../../../common/javascriptHelper";
import { useProject } from "../../../context/ProjectContext";
import DeleteProjectPopup from "../popup/DeleteProjectPopup";

import { useNavigate } from "react-router-dom";
import { useCommonPopup } from '../../../context/CommonPopupContext';
import UserName from '../../inc/UserName';

const ParticipantHeader = (props) => {

  const navigate = useNavigate()
  const auth = useAuth()
  const { popup, confirm } = useCommonPopup()
  const project = useProject()

  useEffect(_ => {

    initPopupMenu()

  }, [])

  const onClickHome = () => {

    navigate("/dashboard")
  }

  const onClickDeleteProject = async () => {

    const result = await popup(<DeleteProjectPopup projectData={project.projectData}/>,
        {
          container: "project",
          name:"project_home_pop",
          style:"del",      
          hasFooter: false,
          useContainer: false,
        }, 
      )
      if (result === true) {
        closeNav()
        navigate("/dashboard")
      }
  }


  const openNav = () => {

    $('.project_head .nav').addClass('active');
    $('.project_head .nav .dim_bg').show();
    $('body').addClass('pop');
  }

  const closeNav = () => {

    $(' .project_head .nav').removeClass('active');
    $(' .project_head .nav .dim_bg').hide();
    $('body').removeClass('pop');
  }

  const onClickProjectHome = () => {
    
    if (!project.projectData?.idproject) return
    closeNav()
    navigate(`../${project.projectId}`)
  }

  const onClickParticipant = () => {

    closeNav()
  }
  
  return (
    <header>       
      <div  className="project_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            { project.projectData?.eCRF === 1 && <i className="icon-menu" onClick={() => {openNav()}}></i> }
            <h1>
                <a onClick={onClickHome}><i className="icon-logo"></i></a>
            </h1>
            <h4>참여자 관리</h4>
            </div>
            <div>
            <UserName/>
            <div className="dot_menu popmenu">
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop">
                <li className="pj_del_btn" onClick={onClickDeleteProject}>프로젝트 삭제</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
      <div className="nav">
      { project.projectData?.eCRF === 1 && 
        <nav>        
          <div><i className="icon-close" onClick={() => {closeNav()}}></i></div>
          <ul>
            <li><a onClick={onClickProjectHome}><i className="icon-home" ></i>프로젝트 홈</a></li>
            <li><a className="active" onClick={onClickParticipant}><i className="icon-team" ></i>참여자 관리</a></li>
          </ul>
        </nav>
      }
      <div className="dim_bg" onClick={() => closeNav()}></div>
      </div>
      </div>
    </header>  
  );
}  
export default ParticipantHeader;