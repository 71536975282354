import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useApi } from "../../context/ApiContext";
import setScreenHeight from "../../common/javascriptHelper";

const Answer = props => {

  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()
  const { link } = useParams()
  const [isInit, setIsInit] = useState(false)
  const [error, setError] = useState(false)

  useEffect(_ => {
    
    window.addEventListener('resize', setScreenHeight);
    setTimeout(() => { 
      setScreenHeight()
    }, 200)

    const query = queryString.parse(location.search);
    if (!!query?.accesstoken) {      
      setIsInit(true)
      navigate(`content${location.search}`, {replace: true})
    } else if (!!link) {
      startAnswerFromLink(link)
    } else {
      setError(true)
    }
    return ()=>{ 
      window.removeEventListener('resize', setScreenHeight)
    }
  }, [])

  const startAnswerFromLink = async (link) => {
    
    const result = await api.post("startAnswerFromLink", {
      link : link,
    })
    
    if (result?.data?.result != "ok") {     
      setError(true)
    } else {      
      const accesstoken = result.data.data.accesstoken      
      setIsInit(true)
      navigate(`content?accesstoken=${accesstoken}`, {replace: true})
    }
  }

  return (
    isInit ? (
      <Outlet />
    ) : (
      error ? (
        <div className="body questionnair">
          <div className="container">
            <div className="inwrap start">
              <div className='questionnair_body'>
                <div></div>
                <h3>
                <br/>
                잘못된 접근입니다.
                </h3>
                <h4>링크를 확인하고 다시 시도해 주세요.</h4>
                <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>
                <h4>불편을 끼쳐드려 죄송합니다.</h4>
              </div>
              <div className="but_wrap">
                <button type="button" className="primary gray large full" onClick={() => {window.close()}}>종료</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="body questionnair">
          <div className="container">
          </div>
        </div>
      )
    )
  )
}
export default Answer