import { useQuestionLogic } from "../../utils/questionUtils"
import { useEffect, useRef, useState, useCallback } from "react"
import {
   SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_QUESTION_TYPE_SHORTANSWER }
   from "../../common/codeHelper"
import { questionImageUrl } from "../../common/imageHelper"
import ChoiceContent from "../../component/ChoiceContent"
import YesOrNoContent from "../../component/YesOrNoContent"
import ShortAnswerContent from '../../components/ShortAnswerContent';
import ScoreContent from '../../component/ScoreContent';

const PreviewQuestion = (props) => {
  const {
    answer,
    reasons,
    checkOK,
    updateAnswer,
    updateReason,
    onSelectAnswer,
    isSelected,
    onClickPrev,
    onClickNext,
  } = useQuestionLogic(props, true)

  const [currentData, setCurrentData] = useState()
  const imageRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)

  const updateImageHeight = useCallback(() => {
    if (!imageRef.current) return
    const newHeight = 5 / 4 * imageRef.current.offsetWidth
    if (newHeight !== imageHeight) {
      setImageHeight(newHeight)
    }
  }, [imageHeight])

  useEffect(() => {
    updateImageHeight()
    const handleResize = () => {
      requestAnimationFrame(updateImageHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [updateImageHeight])

  useEffect(() => {
    if (currentData?.question?.idquestion !== props.currentData?.question?.idquestion) {
      setCurrentData(props.currentData)
      updateImageHeight()
    }
  }, [props.currentData, updateImageHeight])

  const onToggleAnswer = (item, value) => {
    if (currentData.question.multipleselect === 1) {
      var list = !!answer ? answer.split(",") : []
      var ret = list.filter((element) => Number(element) !== Number(item.orderno))
      if (value === true) ret.push(Number(item.orderno))
      var data = ret.join(",")
      updateAnswer(data)
    } else {
      var data = `${item.orderno}`
      updateAnswer(data)
    }
  }

  const essayContent = () => {
    return (
      <>
        <ul className='radio'>
          <li>
            <textarea
              className={`full solo ${answer?.length === 300}`}
              id="textarea_auto"
              placeholder={currentData.question.isconditional === 1 && currentData.question.conditiontype === 'phoneno' ? '휴대폰 번호를 입력해주세요 (- 없이 숫자만 입력)' : "답변 입력"}
              rows="1"
              value={answer}
              onChange={(e) => updateAnswer(e.target.value)}
            />
            <p className='text_length'>{answer?.length}/300</p>
          </li>
        </ul>
      </>
    )
  }

  const dateContent = () => {
    return (
      <>
        <input className={`big ${answer?.length > 0 ? "active" : ""}`} type="date" value={answer} onChange={(e) => updateAnswer(e.target.value)}/>
      </>
    )
  }

  const renderShortAnswerContent = () => {
    return (
      <ShortAnswerContent
        currentData={currentData}
        answer={answer}
        updateAnswer={updateAnswer}
      />
    );
  };

  return (
    !!currentData ? (
      <div className="inwrap">
        <div className='questionnair_body'>
          { currentData.section.commonquestion?.length > 0 &&
            <p className='bg_gray'>
              {currentData.section.commonquestion}
            </p>
          }
          <div className='bg_white'>
          <h3>{currentData.question.orderno}. {currentData.question.content}</h3>
          { currentData.question?.image?.length > 0 &&
            <img
              ref={imageRef}
              style={{maxHeight:`${imageHeight}px`, objectFit: "cover"}}
              src={questionImageUrl(currentData.question.image, "large")}
              onLoad={updateImageHeight}
            />
          }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_YESORNO &&
            <YesOrNoContent
              currentData={currentData}
              isSelected={isSelected}
              onSelectAnswer={onSelectAnswer}
              reasons={reasons}
              updateReason={updateReason}
            />
          }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE &&
            <ChoiceContent
              currentData={currentData}
              answer={answer}
              reasons={reasons}
              isSelected={isSelected}
              onToggleAnswer={onToggleAnswer}
              onSelectAnswer={onSelectAnswer}
              onInputReason={(itemOrderno, value) => updateReason(itemOrderno, value)}
            />
          }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_SCORE &&
            <ScoreContent
              currentData={currentData}
              isSelected={isSelected}
              onSelectAnswer={onSelectAnswer}
            />
          }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_ESSAY && essayContent()}
          { currentData.question?.type === SURVEY_QUESTION_TYPE_DATE && dateContent()}
          { currentData.question?.type === SURVEY_QUESTION_TYPE_SHORTANSWER && renderShortAnswerContent() }
          </div>
          <div className="but_wrap">
            { currentData.hasprev ? (
                <>
                  <button type="button" className="secondary gray large " onClick={onClickPrev}>이전</button>
                  <button type="button"
                    className={`primary large ${!checkOK ? "disabled" : ""}`}
                    disabled={!checkOK}
                    onClick={() => onClickNext(answer)}>
                      다음
                  </button>
                </>
              ) : (
                <button type="button" className="primary large full" onClick={() => onClickNext(answer)}>시작</button>
              )
            }
          </div>
        </div>
      </div>
    ) : (null)
  )
}

export default PreviewQuestion
