import $ from 'jquery';
import { useEffect, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useNavigate } from 'react-router-dom';
import { PARTICIPANT_STATUS } from '../../../common/codeHelper';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const ChangeGroupPopup = props => {
  
  const api = useApi()
  const {alert} = useCommonPopup()  

  const {popupData, onCancel, onConfirm} = props
  const { projectData, participantList } = props

  const [name, setName] = useState("")  
  const [error, setError] = useState("")  
  const [group, setGroup] = useState("A")
  const [groupNameFull, setGroupNameFull] = useState("그룹 A (시험군)")

  useEffect(_ => {
    
    setName(participantList.map((participant) => (participant.identifier))?.join(", "))
    setGroup(participantList?.[0]?.participantgroup ?? "A")
    setGroupNameFull(participantList?.[0]?.groupNameFull ?? "그룹 A (시험군)")
  }, [])



  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onChangeGroup = (group, groupNameFull) => {

    setGroup(group)
    setGroupNameFull(groupNameFull)
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {

    const result = await api.post("updateParticipantGroup", {
      project: projectData.idproject,
      participantgroup: group,
      participants: participantList.map((participant) => (participant.idparticipant))
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg) {
        setError(result?.data?.msg)
      } else {
        api.networkError(result, alert)
      }
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }   
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
          <h5 className="section_tit">참여자 그룹 변경  <span className="close_btn" onClick={onClickClose}></span></h5>
          <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content height_auto ">
                <h4>{name}님의 그룹을 선택해주세요.</h4>
                <div className="selectBox large">
                  <span className="label" onClick={onClickSelect}>{group?.length > 0 ? groupNameFull : "변경할 그룹을 선택해 주세요."}</span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={(e) => onChangeGroup("A", e.target.innerText)}>그룹 A (시험군)</li>
                    <li className="optionItem" onClick={(e) => onChangeGroup("B", e.target.innerText)}>그룹 B (대조군)</li>
                  </ul>
                </div>
                <p className="tip">{error}</p>
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
      {/* <div className="popup_inwrap">
        <h5 className="section_tit">상태 변경 <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto  ">
              <h4>{name}님의 상태를 선택해주세요.
              </h4>
              <div className="selectBox large">
                <span className="label" onClick={onClickSelect}>{status?.length > 0 ? PARTICIPANT_STATUS[status] : "변경할 상태를 선택해 주세요."}</span>
                <ul className="optionList">
                  <li className="optionItem" onClick={() => onChangeStatus("PROCEED")}>{PARTICIPANT_STATUS["PROCEED"]}</li>
                  <li className="optionItem" onClick={() => onChangeStatus("WITHDRAW")}>{PARTICIPANT_STATUS["WITHDRAW"]}</li>
                  <li className="optionItem" onClick={() => onChangeStatus("HALT")}>{PARTICIPANT_STATUS["HALT"]}</li>
                </ul> 
              </div>
              <p className="tip">{error}</p>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                </div>
                <div>
                  <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
                </div>
              </div>
            </div>
        </form>
      </div> */}
    </div>
    
  );  
}
 export default ChangeGroupPopup;