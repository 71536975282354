import { useEffect, useRef, useState } from "react";
import { useCommonPopup } from "../../../context/CommonPopupContext";
import { useProject } from "../../../context/ProjectContext";
import ParticipantHeader from "./ParticipantHeader";
import RegisterParticipantPopup from "../popup/RegisterParticipantPopup";
import { useApi } from "../../../context/ApiContext";
import Pagination from "../../../component/Pagination";
import { PARTICIPANT_STATUS } from "../../../common/codeHelper";
import { handleSelect } from "../../../common/javascriptHelper";
import ChangeStatusPopup from "../popup/ChangeStatusPopup";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterAgreementPopup from "../popup/RegisterAgreementPopup";
import AgreementPreviewPopup from "../popup/AgreementPreviewPopup";
import { isMobile } from "react-device-detect";
import { getFileData } from "../../../common/imageHelper";
import ChangeGroupPopup from "../popup/ChangeGroupPopup";
import { markKeyword } from "../../../common/stringHelper";
import { useLoadingIndicator } from "../../../context/LoadingIndicatorContext";
import ChangeInitialPopup from "../popup/ChangeInitialPopup";
import queryString from "query-string";

const ParticipantManagement = props => {

  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()

  const { popup, alert } = useCommonPopup()
  const {showLoading, stopLoading} = useLoadingIndicator()

  const project = useProject()
   
  // const [allParticipantList, setAllParticipantList] = useState()
  const [participantList, setParticipantList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [originalTotalCount, setOriginalTotalCount] = useState(0)

  const affiliation = useRef("MY")
  // const [affiliation, setAffiliation] = useState("MY")
  const [group, setGroup] = useState("ALL")
  const [groupNameFull, setGroupNameFull] = useState("전체")
  const [selectAll, setSelectAll] = useState(false)
  const searchPage = useRef()
  const searchKeyword = useRef("")

  const [keyword, setKeyword] = useState("")
  const [sortData, setSortData] = useState({
    sort: "",
    order: ""
  })

  // var timer = useRef()
 
  /*=========================================================
   *  useEffect 
   =========================================================*/


  useEffect(_ => {
    return ()=>{ 
      // clearTimeout(timer)
    }
  }, [])

  useEffect(_ => {
    
    const query = queryString.parse(location.search);
    if (!!query?.affiliation) {
      affiliation.current = query?.affiliation
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    } 

  }, [location])

  useEffect(_ => {
        
    getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)

  }, [pageLimit, group, sortData])

  useEffect(_ => {
    
    if (searchPage.current == currentPage) return
    getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)

  }, [currentPage])

  useEffect(_ => {
    
    if (searchKeyword.current !== keyword) {
      getParticipantList(keyword, group, affiliation.current, 0, pageLimit)
    }
  }, [keyword])

  useEffect(_ => {
    
    setSelectAll(participantList?.filter(element => element.selected === false)?.length === 0)     

  }, [participantList])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getParticipantList = async (search, participantgroup, affiliation, page, limit) => {


    searchKeyword.current = search

    const result = await api.post("searchParticipant", {
      project : project.projectId,
      page : page,
      limit : limit,
      sort : sortData.sort,
      order : sortData.order,
      search : search,      
      participantgroup : participantgroup,
      myaffiliation : affiliation === "MY" ? 1 : 0
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      // return [] 
    } else {
      searchPage.current = page
      setCurrentPage(page)
      setTotalCount(result.data.data.totalCount)
      setOriginalTotalCount(result.data.data.originalTotalCount)
      const list = result.data.data.participants.map(element => 
        ({
          ...element,
          ["selected"] : false,
          ["display_initial"] : markKeyword(element.initial, search),
          ["display_identifier"] : markKeyword(element.identifier, search)
        })
      )
      setParticipantList(list)
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
   
   const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {
    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onChangeKeyword = (e) => {

    var value = e.target.value   
    if (keyword === value) return

    setKeyword(value)

    // if (!!timer) clearTimeout(timer)
    // timer = setTimeout(() => {      
    //   getParticipantList(value, group, affiliation, 0, pageLimit)
    // }, 200)
  }

  
  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickSearch = async () => {

    await getParticipantList(keyword, group, affiliation, currentPage, pageLimit)
  }

  /* 검색 필터 (기관) */
  const onChangeAffiliation = (affiliation) => {
   
    // setAffiliation(affiliation)
    navigate(`/project/${project.projectId}/participant?affiliation=${affiliation}`, {replace: true})
  }

  /* 검색 필터 (그룹) */
  const onChangeGroup = (group, groupNameFull) => {
   
    setGroup(group)
    setGroupNameFull(groupNameFull)
  }
  
  const onClickSelect = (e) => {
    handleSelect(e)
  }

  /* 참여자 리스트 전체 선택 */
  const onSelectAllParticipant = (value) => {

    setSelectAll(value)
    setParticipantList(participantList.map((element) => 
      ({
        ...element,
        ["selected"] : value,
      })
    ))
  }

  /* 참여자 리스트 선택 */
  const onSelectParticipant = (item, value) => {

    setParticipantList(participantList.map((element) => 
      item === element ? {
        ...element,
        ["selected"] : value,
      } : element)
    )
  }

  /* 참여자 상태 변경 (개별) */
  const onChangeStatus = async (participant) => {
   
    const result = await popup(
      <ChangeStatusPopup 
        projectData={project.projectData}
        participantList={[participant]}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  /* 참여자 상세 보기 */
  const onClickParticipant = async (participant) => {
   
    navigate(`${participant.idparticipant}`, {state: {affiliation: affiliation.current}})
  }

  const checkGroup = (list) => {

    if (list.length === 0) return true

    var group = list[0].participantgroup
    for (let i = 1; i < list.length; i++) {
      if (group !== list[i].participantgroup) {
        return false; 
      }
    }
    return true
  }

  const onClickRegistAgreement = async (participant) => {

    if (isMobile) {
      const result = await popup(
        <RegisterAgreementPopup 
          projectId={project.projectId}
          participant={participant}
        />,
        {
          container: "project",
          name: "Participant_pop4",
          style: "height_auto", 
          title: "서면 동의서 업로드",
          hasFooter: false,
        }, 
      )
      if (result === true) {
        getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
      }
    } else {
      
    }
  }
 
  const onClickImage = async (e, participant) => {

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return
   
    showLoading()

    const result = await api.post("uploadParticipantAgreement", {
      project: project.projectId,
      participant: participant.idparticipant,
      file: data
    })

    stopLoading()

    if (result?.data?.result != "ok") {
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
      // api.networkError(result, alert)
    } else {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  const onClickAgreementPreview = async (participant) => {

    const result = await popup(
      <AgreementPreviewPopup 
        projectId={project.projectId}
        participant={participant}
      />,
      {
        container: "project",
        name: "Participant_pop5",
        style: "height_auto", 
        title: `${participant.identifier} 서면 동의서`,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  const onClickChangeInitial = async (participant) => {

    const result = await popup(
      <ChangeInitialPopup 
        projectData={project.projectData}
        participant={participant}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }
  
  /* 참여자 그룹 변경 (선택) */
  const onClickChangeGroup = async () => {

    var list = participantList.filter((participant) => participant.selected === true)
    if (list?.length == 0) {
      alert("그룹을 변경할 참여자를 선택해 주세요.")
      return
    }
    if (!checkGroup(list)) {
      alert("참여자의 그룹 정보가 동일해야 일괄 변경이 가능합니다. 다시 확인해주세요.", {
        title: "참여자 그룹 변경 불가"
      })
      return
    }
    // TODO : 설문 참여중 체크 
    var list2 = list.filter((participant) => participant.answered === 1)
    if (list2?.length > 0) {
      alert(`${list2.map(element => element.identifier)?.join(", ")}님은 이미 설문에 참여하였으므로, 그룹을 변경할 수 없습니다. 설문에 참여한 후에는 그룹 변경이 제한됩니다.`, {
        title: "참여자 그룹 변경 불가"
      })
      return
    }

    const result = await popup(
      <ChangeGroupPopup 
        projectData={project.projectData}
        participantList={list}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  /* 참여자 상태 변경 (선택) */
  const onClickChangeStatus = async () => {

    const list = participantList.filter((participant) => participant.selected === true)
    if (list?.length == 0) {
      alert("상태를 변경할 참여자를 선택해 주세요.")
      return
    }

    const result = await popup(
      <ChangeStatusPopup 
        projectData={project.projectData}
        participantList={list}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  /* 참여자 등록 */
  const onClickRegist = async (e) => {

    e.preventDefault()

    const result = await popup(<RegisterParticipantPopup projectData={project.projectData}/>,
      {
        container: "project",
        name: "Participant_pop3",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantList(keyword, group, affiliation.current, currentPage, pageLimit)
    }
  }

  const onClickSort = async (sort) => {

    if (sortData.sort == sort) {
      setSortData({
        sort: sort,
        order: sortData.order == "asc" ? "desc" : "asc"
      })
    } else {
      setSortData({
        sort: sort,
        order: "asc"
      })
    }    
  }

  return (
    <div className="body project participant">
      <ParticipantHeader/>
      <div className="container">
        <div className="inwrap">
        <div className={`full gap ${!!participantList && originalTotalCount === 0 ? "no_item" : ""}`}>
          <section className="">
            <div className="search_wrap">
              <div>
                <div className="search_box">
                  <input type="text" placeholder="식별 ID, 대상자 번호로 검색" value={keyword} onChange={onChangeKeyword} onKeyUp={onKeyEvent}/>
                  <button onClick={onClickSearch}><i className="icon-search"></i></button>
                </div>
                <div className="selectBox">
                  <span className="label" onClick={onClickSelect}>참여자 그룹 : {groupNameFull}   </span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={(e) => onChangeGroup("ALL", e.target.innerText)}>전체</li>
                    <li className="optionItem" onClick={(e) => onChangeGroup("A", e.target.innerText)}>그룹 A (시험군)</li>
                    <li className="optionItem" onClick={(e) => onChangeGroup("B", e.target.innerText)}>그룹 B (대조군)</li>
                  </ul>
                  </div>
              </div>
            </div>  
          </section>
          { !!participantList && ( 
            originalTotalCount > 0 ? (
              <section className="">
                <div className="search_wrap">
                  <div>
                    {project.projectData?.isowner === 1 &&
                      <ul className="tab">
                        <li className={affiliation.current !== "MY" ? "active" : ""} onClick={() => onChangeAffiliation("ALL")}>전체</li>
                        <li className={affiliation.current === "MY" ? "active" : ""} onClick={() => onChangeAffiliation("MY")}>내 기관</li>
                      </ul>
                    }
                    
                  </div>
                  <button className="primary large ParticipantAdd-btn" onClick={onClickRegist}> <i>+</i> 참여자 등록</button>
                </div>  
                <div className="section_in">
                  { participantList?.length > 0 ? (
                    <>
                      <table className="line">
                        <colgroup>
                          <col className="no"/>
                          <col />
                          <col className=""/>
                          <col className=""/>
                          <col className=""/>
                          <col className=""/>
                          <col className="col100"/>
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="checkbox">
                              <label>
                                <input type="checkbox" name="" checked={selectAll} onChange={(e) => onSelectAllParticipant(e.target.checked)}  /><span></span>
                              </label>
                            </th>
                            <th>소속기관</th>
                            <th onClick={() => {onClickSort("identifier")}}>식별 ID(자동부여)
                            <i className={`icon-caretdown ${sortData.sort === "identifier" && sortData.order === "desc" ? "" : "up"}`}></i>
                            </th>
                            <th>대상자 번호</th>
                            <th onClick={() => {onClickSort("participantgroup")}}>참여자 그룹
                            <i className={`icon-caretdown ${sortData.sort === "participantgroup" && sortData.order === "desc" ? "" : "up"}`}></i></th>
                            <th>서면 동의일</th>
                            <th>상태</th>
                        </tr>
                        </thead>
                        <tbody>
                          { participantList?.map((participant, index) => 
                              <tr key={`participant-${index}`}>
                                <td className="checkbox">
                                  <label>
                                    <input type="checkbox" name="" 
                                      checked={participant.selected} 
                                      onChange={(e)=>onSelectParticipant(participant, e.target.checked)}/><span></span>
                                  </label>
                                </td>
                                <td>{participant.affiliation}</td>
                                <td>
                                  <div dangerouslySetInnerHTML={ {__html: participant.display_identifier} }></div>
                                </td>
                                <td className="Participant-no c_primary_6">
                                  <div
                                    onClick={() => { onClickParticipant(participant) }}
                                    dangerouslySetInnerHTML={ {__html: participant.display_initial} }>
                                  </div>
                                  <button className="secondary gray Participant-btn" onClick={() => onClickChangeInitial(participant)}>수정</button>
                                </td>
                                <td>{participant.groupNameFull}</td>
                                <td>
                                  { participant.agreementdate.length > 0 ? 
                                    <button className="text_only agreeUp-btn" onClick={() => {onClickAgreementPreview(participant)}}>{participant.agreementdate}</button>
                                    : isMobile ? <button className="text_only agreeUp-btn" onClick={() => {onClickRegistAgreement(participant)}}>업로드</button>  
                                    : (
                                      <div className="filebox field" align="left" title='이미지 업로드'>
                                        <label htmlFor="files" className="c_primary_6">업로드</label> 
                                        <input type="file" id="files" name="files[]" accept="image/*" capture="environment" onChange={(e) => {onClickImage(e, participant)}}/>
                                      </div>
                                    )
                                  }
                                </td>
                                <td>
                                  <div className="selectBox select_inline">
                                    <span className="label" onClick={() => onChangeStatus(participant)}>{PARTICIPANT_STATUS[participant.status]}</span>
                                    {/* <ul className="optionList">
                                      <li className="optionItem" onClick={() => onChangeStatus(participant, "PROCEED")}>{PARTICIPANT_STATUS["PROCEED"]}</li>
                                      <li className="optionItem" onClick={() => onChangeStatus(participant, "WITHDRAW")}>{PARTICIPANT_STATUS["WITHDRAW"]}</li>
                                      <li className="optionItem" onClick={() => onChangeStatus(participant, "HALT")}>{PARTICIPANT_STATUS["HALT"]}</li>
                                    </ul> */}
                                  </div>
                                </td>
                              </tr>
                            )
                        }     
                        </tbody>
                      </table>    
                      <div className='flex_between'>
                        <div>
                          <button className="secondary gray Participant-btn" onClick={onClickChangeGroup}>참여자 그룹 변경</button>
                          <button className="secondary gray m_l_8 Participant-btn2" onClick={onClickChangeStatus}>상태 변경</button>
                        </div>
                        <div>
                          <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            pageSize={pageLimit}
                            changePageLimit={onChangePageLimit}
                            changeCurrentPage={onChangePage}
                          />
                        </div>
                      </div>
                    </>
                    ) : (
                      <p className='center'>
                        <br/><br/>
                        검색어와 일치하는 참여자가 없습니다.
                      </p>
                    )
                  }
                </div>
              </section>
            ) : (
              <section className="project">
                <div>
                  <img src="../../../assets/images/no_items.png" alt=""/>
                </div>
                <div>
                  <p>
                    등록된 참여자가 없습니다. <br/>
                    참여자 등록 버튼을 눌러 참여자를 등록해 주세요.
                  </p>
                </div>
                <div>
                  <button className="primary large ParticipantAdd-btn" onClick={onClickRegist}> <i>+</i> 참여자 등록</button>
                </div>
              </section>
            )
          )}
         </div>
        </div>
      </div>
    </div>
  )
}


export default ParticipantManagement;