import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../../context/ApiContext';

import { useSurvey } from '../../../context/SurveyContext';
import { useProject } from '../../../context/ProjectContext';
import { SURVEY_QUESTION_TYPE, 
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_STATUS_WRITING,
  SURVEY_STATUS_CLOSED } from '../../../common/codeHelper';
import { decimalFormat } from '../../../common/stringHelper';

const SurveyAnswerSummary = props => {
  
  const api = useApi()
  const project = useProject()
  const survey = useSurvey()
  
  const {affiliation} = props
  const [summaryData, setSummaryData] = useState()
  
  useEffect(_ => {
   
  }, [])

  useEffect(_ => {
    
    if (project.projectData != null) {
      showSummary()
    }
   
  }, [project.projectData, affiliation])


  const showSummary = async () => {

    const result = await api.post("showSummary", {
      survey: survey.surveyId,
      myaffiliation : affiliation === "MY" ? 1 : 0
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {       
    
      var index = 0      
      result.data.data.questions.forEach((question, index1) => {                
        question["index"] = ++index        
        const totalanswercount = question.totalanswercount
        
        var maxcount = 0
        question.items?.forEach((item, index2) => {          
          item["rate"] = totalanswercount > 0 ? Math.min(100, item.answercount / totalanswercount * 100) : 0    
          if (maxcount < item.answercount) maxcount = item.answercount
        })  
        question["maxcount"] = maxcount + 1 
        question.items?.forEach((item, index2) => {          
          item["displayRate"] = totalanswercount > 0 ? Math.min(100, item.answercount / maxcount * 100) : 0    
          
        }) 
      });
      setSummaryData(result.data.data)
    }
  }

  const ChoiceContent = (props) => {

    const {question} = props

    return (
      <>
        <div className="search_wrap">
          <h5 className='bold'>{question.sectionorderno}-{question.questionorderno}. {question.questioncontent}</h5>
          {question?.participantgroup !== "ALL" && <span className='group'>{question?.groupNameFull} 만</span>}
        </div>        
        <div className='graph_wrap'>
          <div className='gray_bg'>
            { question.items?.map((item, index) =>     
              <dl key={`item-graph-${question.sectionorderno}-${question.questionorderno}-${index}`}>
                <dt>{item.content}</dt>
                <dd>
                  <div style={{padding: "2px 0px", width:`${item.displayRate}%`, color:item.displayRate < 20 ? "#000" : "#fff"}}>
                    &nbsp;&nbsp;{item.answercount}{item.rate > 0 ? `(${decimalFormat(item.rate, 2)}%)` : ""}&nbsp;&nbsp;
                  </div>
                </dd>
              </dl>   
            )}
            <div className='num'>
              { [...Array(question.maxcount)].map((num, index) =>
                  <span key={`item-grape-regend-${question.sectionorderno}-${question.questionorderno}-${index}`}>{`${index}`}</span>
              )}
            </div>
          </div>
          <table className='line'>
              <colgroup>
            <col className='col200'/><col />
            </colgroup>
            <thead>
              <tr>
                <th>답변</th>
                <th>응답수</th>
              </tr>
            </thead>
            <tbody>
              { question.items?.map((item, index) =>     
                <tr key={`item-${question.sectionorderno}-${question.questionorderno}-${index}`}>
                  <td>{item.content}</td>
                  <td>{item.answercount}{item.rate > 0 ? `(${decimalFormat(item.rate, 2)}%)` : ""}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const ScoreContent = (props) => {

    const {question} = props

    return (
      <>
        <div className="search_wrap">
          <h5 className='bold'>{question.sectionorderno}-{question.questionorderno}. {question.questioncontent}</h5>
          {question?.participantgroup !== "ALL" && <span className='group'>{question?.groupNameFull} 만</span>}
        </div>
        <div className='graph_wrap vertical '>
          <div className='gray_bg'>
            <dl>
              <dt style={{height:"300px", justifyContent: "space-between"}}>
                { [...Array(question.maxcount)].map((num, index) =>
                    <span key={`item-grape-regend-${question.sectionorderno}-${question.questionorderno}-${index}`} style={{marginBottom: "0px"}}>{`${question.maxcount - index - 1}`}</span>
                  )
                }
              </dt>
              { question.items?.map((item, index) =>     
                <dd key={`item-graph-${question.sectionorderno}-${question.questionorderno}-${index}`}>
                  <div style={{padding: "0px 4px", height:`${item.displayRate}%`, color:item.displayRate < 20 ? "#000" : "#fff",}}>
                  </div>
                  <div style={{padding: "0px 4px", height:`${item.displayRate}%`, color:item.displayRate < 20 ? "#000" : "#fff", minHeight: "20px", background:"transparent"}}>
                    {item.answercount}{item.rate > 0 ? `(${decimalFormat(item.rate, 2)}%)` : ""}
                  </div>
                  <span>{item.score}</span>
                </dd> 
              )}
            </dl>
          </div>
          <table className='line'>
            <colgroup>
            <col className='col200'/><col />
            </colgroup>
            <thead>
              <tr>
                <th>답변</th>
                <th>응답수</th>
              </tr>
            </thead>
            <tbody>
              { question.items?.map((item, index) =>     
                <tr key={`item-${question.sectionorderno}-${question.questionorderno}-${index}`}>
                  <td>{item.content}</td>
                  <td>{item.answercount}{item.rate > 0 ? `(${decimalFormat(item.rate, 2)}%)` : ""}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const EssayContent = (props) => {

    const {question} = props

    return (
      <>
        <div className="search_wrap">
          <h5 className='bold'>{question.sectionorderno}-{question.questionorderno}. {question.questioncontent}</h5>
          {question?.participantgroup !== "ALL" && <span className='group'>{question?.groupNameFull} 만</span>}
        </div>
        <ul className='bg_gray_list'>
          { question.answers?.map((answer, index) =>     
            <li key={`item-${question.sectionorderno}-${question.questionorderno}-${index}`}>
              {answer}
            </li>
          )}
        </ul>
      </>
    )

  }

  return (
    <div className='full gap' >
      <section className='nameList'>
        <ul className='top_wrap'>
          <li>
            <p>응답</p>
            <h3>{summaryData?.counts?.completedcount?? "-"}</h3>
          </li>
          <li>
            <p>미응답</p>
            <h3>{summaryData?.counts?.notansweredcount?? "-"}</h3>
          </li>
          <li>
            <p>등록 참여자</p>
            <h3>{summaryData?.counts?.participantcount?? "-"}</h3>
          </li>
          <li>
            <p>응답률</p>
            <h3>{!!summaryData?.counts?.participantcount ? decimalFormat(Math.min(100, summaryData.counts.completedcount / summaryData.counts.participantcount * 100), 2) : "-"}%</h3>
          </li>
          { project.projectData?.eCRF === 1 && survey.surveyData?.multipleresponse === 1 && <li>
              <p>총 응답 건수</p>
              <h3>{summaryData?.counts?.totalresponsecount?? "-"}</h3>
            </li>
          }
        </ul>
      </section>
      { summaryData?.questions?.map((question, index) =>  
        <section key={`question-${question.sectionorderno}-${question.questionorderno}`}>
          { question?.type === SURVEY_QUESTION_TYPE_YESORNO && <ChoiceContent question={question}/> }
          { question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && <ChoiceContent question={question}/> }
          { question?.type === SURVEY_QUESTION_TYPE_SCORE && <ScoreContent question={question}/> }
          { question?.type === SURVEY_QUESTION_TYPE_ESSAY && <EssayContent question={question}/> }
          { question?.type === SURVEY_QUESTION_TYPE_DATE && <EssayContent question={question}/> }
        </section>      
      )}
    </div>
  )
}
export default SurveyAnswerSummary;