import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useProject } from '../../../context/ProjectContext';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useAuth } from '../../../context/AuthContext';
import { validateInput, validateMobilePhoneNumber} from '../../../common/stringHelper';

const RegisterParticipantPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const { alert } = useCommonPopup()
  const api = useApi()
  const auth = useAuth()
  const project = useProject()

  const [name, setName] = useState("")
  const [error, setError] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")

  const [affiliation, setAffiliation] = useState()
  const [group, setGroup] = useState("A")
  const [groupNameFull, setGroupNameFull] = useState("그룹 A (시험군)")
  
  // const [affiliationList, setAffiliationList] = useState()

  useEffect(()=> {

    setAffiliation(auth.adminInfo?.affiliation)
    // getAffiliationList()

    return () => {
    }
  }, []);

  useEffect(()=> {

    setAffiliation(auth.adminInfo?.affiliation)
   
  }, [auth.adminInfo]);

  // const getAffiliationList = async() => {

  //   const result = await api.post("getAffiliationList", {
  //   })
    
  //   if (result?.data?.result != "ok") {
  //     api.networkError(result, alert)
  //   } else {
  //     setAffiliationList(result.data.data.affiliations)
  //   }
  // }

  const onChangeName = (e) => {

    const value = e.target.value
    setName(value)

    if (value.length > 0 && !validateInput(value)) {
      setError("대상자 번호는 영문, 숫자, 특수문자(-_) 조합으로 지정해주세요.")
    } else {
      setError("")
    }
  }

  const onChangePhone = (e) => {

    const value = e.target.value
    setPhone(value)

    if (value.length > 0 && !validateInput(value, "number")) {
      setPhoneError("휴대전화 번호는 숫자만 입력해주세요.")
    } else {
      setPhoneError("")
    }
  }

  const onClickSelect = async(e) => {

    handleSelect(e)
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }


  // const getIdentifier = () => {

  //   var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  //   var key = "";
  
  //   for (var i = 0; i < 6; i++) {
  //     var randomIndex = Math.floor(Math.random() * chars.length);
  //     key += chars.charAt(randomIndex);
  //   }
  
  //   return key;
  // }

  const onClickConfirm = async (e) => {

    e.preventDefault()

    if (!affiliation) {
      alert("기관을 선택해 주세요.")
      return 
    }

    if (name.length === 0) {
      alert("대상자 번호를 입력해 주세요.")
      return 
    }

    // phone optional
    if (phone.length > 0 && !validateMobilePhoneNumber(phone)) {
      alert("올바른 휴대전화 번호를 입력해 주세요.")
      return 
    }
    
    const result = await api.post("registerParticipant", {
      project: props.projectData.idproject,
      // identifier: getIdentifier(),
      initial: name,
      participantgroup: group,
      affiliation,
      phone,
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }  

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">참여자 등록<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e)=>e.preventDefault()}>
          <div className="pop_content height_auto " style={{gap:"10px"}}>
            <div>
              <label>소속기관</label>
              <div className="selectBox large full">
                <span className="label c_disabled">{affiliation?? "-"}</span>
                {/* <span className="label" onClick={onClickSelect}>{affiliation?? "기관을 선택해 주세요."}</span>
                <ul className="optionList">
                  { affiliationList?.map((affiliation, index) =>
                      <li className="optionItem" key={`affiliation-${index}`} onClick={() => setAffiliation(affiliation.name)}>{affiliation.name}</li>
                    )
                  }
                </ul> */}                
              </div>
              <p className="tip"></p>
            </div>
            {/* <div>
              <label>시험대상자 식별번호</label>
              <input type="text" id="survey-link" placeholder="자동으로 생성됩니다." value="" className="full big disabled " disabled={true}/>
            </div> */}
            <div>
              <label>대상자 번호</label>
              <input type="text" id="survey-link" placeholder="대상자 번호를 입력해주세요." value={name} 
                className={`full big ${error?.length > 0 ? "error" : ""}`} onChange={onChangeName}/>
              <p className="tip">{error}</p>
            </div>
            <div>
              <label>휴대전화 번호</label>
              <input type="text" id="survey-link" placeholder="숫자만 입력해주세요." value={phone} 
                className={`full big ${phoneError?.length > 0 ? "error" : ""}`} onChange={onChangePhone}/>
              <p className="tip">{phoneError}</p>
            </div>
            <div>
              <label>참여자 그룹</label>
              <div className='selectBox_wrap'>
                <div className="selectBox large full">
                  <span className="label" onClick={onClickSelect}>{groupNameFull}</span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={(e) => {setGroup("A"); setGroupNameFull(e.target.innerText)}}>그룹 A (시험군)</li>
                    <li className="optionItem" onClick={(e) => {setGroup("B"); setGroupNameFull(e.target.innerText)}}>그룹 B (대조군)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large "  onClick={onClickConfirm}>확인</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default RegisterParticipantPopup;