import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApi } from "../../context/ApiContext";
import queryString from 'query-string';
import AnswerSection from './AnswerSection';
import AnswerQuestion from './AnswerQuestion';
import AnswerHeader from './AnswerHeader';
import setScreenHeight from '../../common/javascriptHelper';

const AnswerContent = props => {

  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()
  const [accessToken, setAccessToken] = useState()

  const oldData = useRef()
  const [currentData, setCurrentData] = useState()
  const [error, setError] = useState()

  useEffect(_ => {

    window.addEventListener('resize', setScreenHeight);
    setTimeout(() => { 
      setScreenHeight()
    }, 200)

    return ()=>{ 
      window.removeEventListener('resize', setScreenHeight)
    }

  }, [])

  useEffect(_ => {
    
  }, [props])

  useEffect(_ => {
    
    const query = queryString.parse(location.search);
    if (!!query?.accesstoken) {
      setAccessToken(query.accesstoken)
      showQuestion(query.accesstoken, location.state?.moveaction?? "START")
    } else {
      navigate("/answer")
    }

  }, [location])

  useEffect(_ => {
    
    oldData.current = {
      ...currentData
    }
    setError(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [currentData])

  const showQuestion = async (accesstoken, moveaction) => {
    const result = await api.request("/surveys/show_question/", {
      method: "post",
      params: {
        accesstoken : accesstoken,
        moveaction : moveaction,
        currentsectionorderno : location.state?.currentsectionorderno?? 0,
        currentquestionorderno : location.state?.currentquestionorderno?? 0,
        answercontent: location.state?.answer
      }
    })

    if (result?.status !== 200) {
      setError(true)
    } else {
      setCurrentData(result.data)
    }
  }

  const onClickPrev = () => {
    navigate(`${location.search}`, {
      state: { 
        moveaction: "PREV",
        currentsectionorderno : currentData?.section?.orderno, 
        currentquestionorderno : currentData?.question?.orderno
      },
      replace: true
    })
  }

  const onClickNext = (answer) => {
    navigate(`${location.search}`, {
      state: { 
        moveaction: "NEXT",
        currentsectionorderno: currentData?.section?.orderno,
        currentquestionorderno: currentData?.question?.orderno,
        answer
      },
      replace: true
    });
  }

  const onClickRestart = async () => {

    const result = await api.post("restartAnswer", {
      accesstoken : accessToken
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)       
    } else {
      if (!!result.data.data.accesstoken) {
        navigate(`/answer?accesstoken=${result.data.data.accesstoken}`, {
          replace: true
        })
      }
    } 
  }

  const AnswerComplete = () => {

    const onClickClose = () => {
  
      window.close()
    }
  
    return ( 
      <div className="inwrap start">
        <div className='questionnair_body'>
          <div></div>
          <h3>
          👍👏🥰<span className='red_c'>❤</span> <br/>
          설문을 완료했습니다.
          </h3>
          <h4 className=''>참여해주셔서 진심으로 감사드립니다.</h4>
          </div>
          { currentData?.multipleresponse ===1 && <div className="but_wrap">
              <button type="button" className="primary large full" onClick={onClickRestart}>다시하기</button>
            </div>
          }
        </div> 
    )
  }

  const AnswerError = () => {

    return ( 
      <div className="inwrap start">
        <div className='questionnair_body'>
          <div></div>
          <h3>
          😵 <br/>
          설문지를 표시할 수 없습니다.
          </h3>
          <h4>이전 화면으로 돌아가 다시 시도해보세요. </h4>
          <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>
          <h4>불편을 끼쳐드려 죄송합니다.</h4>
          </div>
          <div className="but_wrap">
            <button type="button" className="secondary gray large full" onClick={onClickPrev}>이전</button>
        </div>
      </div>
    )
  }

  return (      
    <div className="body questionnair">
      <AnswerHeader currentData = {currentData}/>
      <div className="container">
        { error === true ? (
            <AnswerError/>
          ) :  !!currentData && currentData.completed === 1 ? (
            <AnswerComplete/>
          ) : !!currentData && !currentData.question.orderno ? (
            <AnswerSection 
              currentData = {currentData}
              accessToken = {accessToken}
              onPrev = {onClickPrev}
              onNext = {onClickNext}
            />
          ) : !!currentData && !!currentData.question.orderno ? (
            <AnswerQuestion 
              currentData = {currentData}
              accessToken = {accessToken}
              onPrev = {onClickPrev}
              onNext = {onClickNext}
            />
          ) : (
            null
          )           
        }
      </div>
    </div>
  );
}
  
export default AnswerContent;
